import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from './utils';

const ErrorStyles = styled.div`
  padding: 2rem;
  background: ${theme.biege};
  margin: 2rem 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-top: 3px solid #c94245;

  p {
    color: #c94245;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2.2rem;
    font-weight: 100;
    font-family: ${theme.bodyFont};
    letter-spacing: ${theme.bodyFontLetterSpacing};
  }

  strong {
    margin-right: 1rem;
  }
`;

const DisplayError = ({ error }) => {
  if (!error || !error.message) return null;
  if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors.length
  ) {
    return error.networkError.result.errors.map((error, i) => (
      <ErrorStyles key={i}>
        <p data-test="graphql-error">
          {error.message.replace('GraphQL error: ', '')}
        </p>
      </ErrorStyles>
    ));
  }
  return (
    <ErrorStyles>
      <p data-test="graphql-error">
        {error.message.replace('GraphQL error: ', '')}
      </p>
    </ErrorStyles>
  );
};

DisplayError.defaultProps = {
  error: {}
};

DisplayError.propTypes = {
  error: PropTypes.object
};

export default DisplayError;
