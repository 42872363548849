import styled from 'styled-components';
import { theme } from '../utils';

export const PrimaryButton = styled.button`
  background: ${theme.primaryButtonBGColor};
  border: solid ${theme.primaryButtonBGColor} 2px;
  border-radius: ${theme.borderRadius};
  color: ${theme.primaryButtonColor};
  font-family: ${theme.primaryButtonFontFamily};
  font-size: ${theme.primaryButtonFontSize};
  line-height: ${theme.primaryButtonLineHeight};
  letter-spacing: ${theme.primaryButtonLetterSpacing};
  height: ${theme.primaryButtonHeight};
  width: ${theme.primaryButtonWidth};
  text-transform: ${theme.primaryButtonTextTransform};
  margin: ${theme.primaryButtonMargin};
  padding-left: ${theme.primaryButtonPaddingLeft};
  padding-right: ${theme.primaryButtonPaddingRight};
  cursor: pointer;
  transition: 0.25s;
  &:hover {
    color: ${theme.primaryButtonColor};
    background-color: #feb794;
    border: solid #feb794 2px;
  }
`;

export const FWPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
`;

export const OutlineButton = styled.button`
  background: ${theme.secondaryButtonBGColor};
  border: solid ${theme.secondaryButtonBorderColor} 1px;
  border-radius: ${theme.borderRadius};
  color: ${theme.secondaryButtonColor};
  font-family: ${theme.secondaryButtonFont};
  font-size: ${theme.secondaryButtonFontSize};
  line-height: ${theme.secondaryButtonLineHeight};
  letter-spacing: ${theme.secondaryButtonLetterSpacing};
  height: ${theme.secondaryButtonHeight};
  width: ${theme.secondaryButtonWidth};
  text-transform: ${theme.secondaryButtonTextTransform};
  margin: ${theme.secondaryButtonMargin};
  cursor: pointer;
  transition: 0.25s;
  &:hover {
    color: ${theme.secondaryButtonColor};
    background-color: #f5f5f5;
    border: solid ${theme.secondaryButtonBorderColor} 1px;
  }
`;
