import styled, { css } from 'styled-components';

const size = {
  small: 700,
  med: 1100,
  large: 1140
};

export const width = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
