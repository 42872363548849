import styled, { ThemeProvider, injectGlobal } from 'styled-components';
import Router from 'next/router';
import NProgress from 'nprogress';
import NavBrand from './NavBrand';
import NavItems from './NavItems';
import { theme, width } from '../utils';

Router.onRouteChangeStart = () => {
  NProgress.start();
};
Router.onRouteChangeComplete = () => {
  NProgress.done();
};
Router.onRouteChangeError = () => {
  NProgress.done();
};

const Bar = styled.header`
  position: fixed;
  background-color: ${theme.bgColor};
  top: 0px;
  left: 0px;
  width: 100%;
  height: ${theme.navbarHeight};
  border-bottom: 1px solid #d6c8c5;
  z-index: 5000;
  opacity: 1;

  ${width.small`
    height: 50px;
  `}

  .nav-container {
    background-color: ${theme.bgColor};
    position: relative;
    margin: 0 auto;
    max-width: 90%;
    min-width: 90%;
    padding: 0 20px;
    z-index: 1000;
  }
`;

const NavigationBar = () => (
  <div>
    <Bar>
      <div className="nav-container">
        <NavBrand />
        <NavItems />
      </div>
    </Bar>
  </div>
);

export default NavigationBar;
