import styled from "styled-components";
import { theme, width } from "../utils";

const DeviceMenuStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100vh;
  background-color: rgba(201, 193, 183, 0.2);
  z-index: 5000;
  display: none;
  transition: all 0s;

  ${props => props.open && `display: block;`}

  .modal-container {
    position: absolute;
    background-color: ${theme.navBarBGColor};
    height: 100%;
    top: 0px;
    right: 0px;
    width: 550px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;

    ${width.large`
      width: 50%;
    `};

    ${width.med`
      width: 60%;
    `};

    ${width.small`
      padding-top: 60px;
      width: 100%;
    `};
  }

  .close {
    position: fixed;
    top: 10px;
    right: 30px;
    height: 24px;
    width: 24px;
    background: ${theme.biege};
    outline: none;
    border: none;
    z-index: 10000;
    cursor: pointer;

    /* ${width.small`
      position: absolute;
      height: 24px;
      width: 24px;
    `}; */
  }

  a {
    color: ${theme.green};
    font-family: ${theme.bodyFontBold};
    font-size: 1.4rem;
    line-height: 3.6rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
  }

  .menu-divider {
    position: relative;
    left: 0px;
    height: 2px;
    width: 50px;
    background: ${theme.green};
    margin: 20px 0;
  }

  .device-sign-out {
    position: relative;

    .sign-out-button {
      position: absolute;
      left: -60px;
      top: 0px;
      width: 200px;
      background: ${theme.biege};
      color: ${theme.lightGreen};
      font-family: ${theme.bodyFontBold + "!important"};
      font-size: 1.4rem;
      line-height: 3.6rem;
      letter-spacing: 2px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
`;

export default DeviceMenuStyles;
