import Link from "next/link";
import styled from "styled-components";
import { width } from "../utils";

const SiteLogo = styled.div`
  position: absolute;
  left: 30px;
  top: 15px;
  cursor: pointer;

  img {
    height: 60px;
  }

  ${width.med`
    display: none;
  `}
`;

const DeviceLogo = styled.div`
  display: none;
  position: absolute;
  left: 30px;
  top: 15px;
  cursor: pointer;

  ${width.med`
    display: block;
  `}

  ${width.small`
    display: block;
    top: -7px;
    left: 0px;
    img {
      height: 34px;
      width: auto;
    }
  `}
`;

class NavBrand extends React.Component {
  render() {
    return (
      <div>
        <Link href="/library">
          <a>
            <SiteLogo>
              <img src="/static/buddhacloud-logo.svg" alt="Buddhacloud Logo" />
            </SiteLogo>
          </a>
        </Link>
        <Link href="/library">
          <a>
            <DeviceLogo>
              <img src="/static/site-logo.svg" alt="Buddhacloud Logo" />
            </DeviceLogo>
          </a>
        </Link>
      </div>
    );
  }
}

export default NavBrand;
