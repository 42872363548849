import styled from 'styled-components';
import { theme, width } from '../utils';

const ModalStyles = styled.div`
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100vh;
  background-color: rgba(201, 193, 183, 0.2);
  z-index: 5000;
  display: none;
  transition: all 0s;

  ${props => props.open && `display: block;`}

  .modal-container {
    position: absolute;
    background-color: ${theme.biege};
    height: 100%;
    top: 0px;
    right: 0px;
    width: 550px;
    padding: 10px 40px 40px 40px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    ${width.large`
      width: 50%;
    `};

    ${width.med`
      background-color: ${theme.biege};
      width: 60%;
    `};

    ${width.small`
      width: 100%;
      padding: 0px 40px 100px 40px;
    `};
  }

  .modal-title-container {
    padding-bottom: 10px;
    margin-bottom: 30px;

    h1 {
      margin: 0;
      line-height: 150%;
    }

    p {
      text-align: left;
      font-size: 1.5rem;
      line-height: 2.2rem;
      letter-spacing: ${theme.bodyFontLetterSpacing};
    }
  }

  .modal-button-container {
    display: grid;
    grid-template-columns: 60px auto;
    grid-gap: 20px;
  }

  .modal-button {
    position: absolute;
    background: ${theme.biege};
    bottom: 30px;
    width: 85%;
    outline: none;

    ${width.med`
      position: relative;
      width: 100%;
      margin-top: 30px;
    `}

    ${width.small`
      position: relative;
      width: 100%;
    `}
  }

  .close {
    position: fixed;
    top: 40px;
    right: 40px;
    height: 30px;
    width: 30px;
    background-color: ${theme.biege};
    outline: none;
    border: none;
    z-index: 10000;
    cursor: pointer;

    ${width.small`
      position: absolute;
      top: 10px;
      right: 30px;
    `};
  }

  ul {
    list-style: none;
  }

  li {
    margin: 0px 0;

    a {
      color: ${theme.green};
      font-size: 2.2rem;
      line-height: 2rem;
      cursor: pointer;
    }
  }
`;

export default ModalStyles;
