import styled from 'styled-components';
import { width } from '../utils';

const AccessStyles = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
  height: 100vh;

  ${width.large`
    grid-template-columns: 1fr;
    grid-gap: 0px;
    padding-top: 78px;
    width: 100%;
  `}

  ${width.med`
    padding: 100px 0 50px 0;
  `}

  ${width.small`
    padding: 100px 0 50px 0;
    max-width: 100%;
    margin: 0 auto;
  `}

  .info {
    display: flex;
    justify-content: center;
    align-items: center;

    ${width.med`
      display: flex;
      justify-content: center;
      align-items: bottom;
  `}
  }

  .hero-image {
    position: relative;
    background-image: url('/static/hero.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    ${width.large`
      display: none;
    `}
  }

  .welcome-image {
    position: relative;
    background-image: url('/static/welcome-bg.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    ${width.large`
      display: none;
    `}
  }

  #credit {
    color: white;
    position: absolute;
    bottom: 20px;
    right: 20px;
    text-decoration: none;
  }
`;

export default AccessStyles;
