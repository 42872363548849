import styled from 'styled-components';
import { theme } from '../utils';

const RequiredStyles = styled.span`
  display: inline;
  color: black;
  font-family: ${theme.labelFontFamily};
  font-size: 1.5rem;
  margin-left: -2px;
`;

export default class RequiredIcon extends React.Component {
  render() {
    return <RequiredStyles> *</RequiredStyles>;
  }
}
