import styled, { keyframes } from "styled-components";
import { theme, width } from "../utils";

const loading = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 100%;
  }
`;

const FormStyles = styled.form`
  margin: 50px auto;
  /* overflow: visible; */

  h1 {
    color: ${theme.formTitleColor};
    font-family: ${theme.formTitleFontFamily};
    font-size: ${theme.formTitleFontSize};
    line-height: ${theme.formTitleLineHeight};
    letter-spacing: ${theme.formTitleFontLetterSpacing};
    margin-top: ${theme.formTitleMargin};
    margin-bottom: 50px;
    text-transform: ${theme.formTitleTextTransform};
  }

  .form-group {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }

  .form-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 0 auto;

    ${width.med`
      grid-template-columns: 1fr;
      grid-gap: 0px;
    `};
  }

  .partner-row {
    display: grid;
    grid-template-columns: 130px auto auto;
    grid-gap: 20px;

    ${width.large`
      width: 100%;
    `};

    ${width.med`
      grid-template-columns: 1fr;
      grid-gap: 0px;
      width: 100%;
    `};
  }

  label {
    color: ${theme.labelColor};
    font-family: ${theme.labelFontFamily};
    font-size: ${theme.labelFontSize};
    letter-spacing: ${theme.labelFontLetterSpacing};
    line-height: ${theme.labelLineHeight};
    text-transform: ${theme.labelTextTransform};
  }

  input {
    background: ${theme.bgColor};
    border: none;
    border-bottom: 1px solid ${theme.primaryColor};
    border-radius: ${theme.inputBorderRadius};
    color: ${theme.inputFontColor};
    height: ${theme.inputHeight};
    font-family: ${theme.inputFontFamily};
    font-size: ${theme.inputFontSize};
    line-height: ${theme.inputLineHeight};
    letter-spacing: ${theme.formInputLetterSpacing};
    padding-left: ${theme.inputPaddingLeft};
    padding-right: ${theme.inputPaddingRight};
    width: 100%;
    outline: none;
    -webkit-border-radius: none;
    -webkit-appearance: none;

    /* &:focus {
      border: solid ${theme.primaryColor} 2px;
    } */
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button,
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  select {
    background-color: transparent;
    background: url('/static/icons/dd-arrow.svg');
    background-position: 100% 50%;
    background-repeat: no-repeat;
    border: none;
    border: solid ${theme.inputBorderColor} 1px;
    height: 38px;
    width: 100%;
    padding-left: 0;
    color: ${theme.primaryButtonBGColor};
    font-family: ${theme.formInputFont};
    font-size: 1.6rem;
    line-height: 1.8rem;
    letter-spacing: ${theme.formInputLetterSpacing};
    outline: none;
    -webkit-appearance: none;
    &:focus {
      border: solid ${theme.primaryColor} 1px;
    }
  }

  select::-ms-expand {
    display: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    text-indent: 1px;
    text-overflow: '';
    appearance: none; /* remove default arrow */
  }

  textarea {
    background: transparent;
    border: none;
    border-bottom: solid ${theme.primaryColor} 1px;
    height: 100px;
    width: 100%;
    color: ${theme.green};
    font-family: ${theme.formInputFont};
    font-size: 1.6rem;
    line-height: 1.8rem;
    letter-spacing: ${theme.formInputLetterSpacing};
    outline: none;
    white-space: pre-line;
  }

  .library-content {
    height: 500px;
  }

  fieldset {
    border: 0;
    padding: 0;
    &[disabled] {
      opacity: 0.5;
    }
    &::before {
      margin-bottom: 20px;
      height: 4px;
      content: '';
      display: block;
      background-image: linear-gradient(
        to right,
        #ffcfb7 0%,
        #ffffff 50%,
        #ffcfb7 100%
      );
      opacity: 0;
    }
    &[aria-busy='true']::before {
      background-size: 50% auto;
      animation: ${loading} 0.5s linear infinite;
      opacity: 1;
    }
  }
  ::-webkit-input-placeholder {
    color: ${theme.primaryColor};
  }
  ::placeholder {
    color: ${theme.primaryColor};
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: red;
  }
  ::-ms-input-placeholder {
    color: ${theme.primaryColor};
  }

  button {
    /* background: ${theme.primaryButtonBGColor};
    border: solid ${theme.primaryButtonBorderColor} 0px;
    border-radius: ${theme.primaryButtonBorderRadius};
    color: ${theme.primaryButtonColor};
    font-family: ${theme.primaryButtonFont};
    font-size: ${theme.primaryButtonFontSize};
    line-height: ${theme.primaryButtonLineHeight};
    letter-spacing: ${theme.primaryButtonLetterSpacing};
    height: ${theme.primaryButtonHeight};
    width: ${theme.primaryButtonWidth};
    text-transform: ${theme.primaryButtonTextTransform};
    margin: ${theme.primaryButtonMargin}; */
    cursor: pointer;
    &:hover {
      /* color: ${theme.primaryButtonColor};
      background-color: ${theme.primaryButtonBGColor};
      border: solid ${theme.primaryButtonBorderColor} 2px; */
    }
  }

  .secondaryButton {
    background: ${theme.secondaryButtonBGColor};
    border: solid ${theme.secondaryButtonBorderColor} 2px;
    border-radius: ${theme.secondaryButtonBorderRadius};
    color: ${theme.secondaryButtonColor};
    font-family: ${theme.secondaryButtonFont};
    font-size: ${theme.secondaryButtonFontSize};
    line-height: ${theme.secondaryButtonLineHeight};
    letter-spacing: ${theme.secondaryButtonLetterSpacing};
    height: ${theme.secondaryButtonHeight};
    width: ${theme.secondaryButtonWidth};
    text-transform: ${theme.secondaryButtonTextTransform};
    margin: ${theme.secondaryButtonMargin};
    cursor: pointer;
    &:hover {
      color: ${theme.secondaryButtonColor};
      background-color: ${theme.secondaryButtonBGColor};
      border: solid ${theme.secondaryButtonBorderColor} 2px;
    }
  }

  .button-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  .facebook {
    background: transparent;
    border: none;
    color: ${theme.green};
    font-family: ${theme.bodyFont};
    font-size: 1.5rem;
    letter-spacing: ${theme.bodyFontLetterSpacing};
    line-height: 2.2rem;
    height: 22px;
    width: 100%;
    text-align: center;
    text-transform: none;
    cursor: pointer;
    &:hover {
      color: ${theme.green};
      background-color: ${theme.biege};
    }
  }

  p {
    text-align: center;
  }

  /*.edit-view {
    text-align: left;
    margin-bottom: 20px;
  } */

  .account-link {
    color: ${theme.primaryColor};
    font-size: 1.5rem;
    letter-spacing: ${theme.bodyFontLetterSpacing};
    width: 100%;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
    font-family: 'Avenir-Book';
  }

  .meditation-divider {
    border: 1px solid ${theme.primaryColor};
    color: ${theme.primaryColor};;
    padding: 10px;
    width: 100%;
    margin: 50px 0 20px 0;
    text-transform: uppercase;
  }

  .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 50px auto;

    .avatar-edit {
      position: absolute;
      right: 12px;
      z-index: 1;
      top: 10px;
      input {
        position: relative;
        display: none;
        + label {
          position: absolute;
          display: inline-block;
          top: 75px;
          left: -68px;
          width: 32px;
          height: 32px;
          margin-bottom: 0;
          border-radius: 100%;
          cursor: pointer;
          font-weight: normal;
          transition: all 0.2s ease -in -out;
          background-image: url("/static/avatar-plus-icon.svg");
          background-repeat: no-repeat;
          background-size: contain;
          &:hover {
            background-image: url("/static/avatar-plus-icon-white.svg");
          }
          &:after {
            color: #757575;
            position: absolute;
            top: 10px;
            left: 0;
            right: 0;
            text-align: center;
            margin: auto;
            background: url("./static/close.svg");
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
      }
    }
    .avatar-preview {
      width: 114px;
      height: 114px;
      position: relative;
      border-radius: 100%;
      /* border: 1px solid black; */
      margin: 0 auto;
      > div {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/static/user-avatar.svg");
      }
    }
  }
`;

export default FormStyles;
