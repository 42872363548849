import Link from "next/link";
import styled from "styled-components";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import Router from "next/router";
import Error from "../ErrorMessage";
import FormStyles from "../styles/FormStyles";
import { CURRENT_USER_QUERY } from "../utils/User";
import { width } from "../utils";
import RequiredIcon from "../utils/RequiredIcon";
import { PrimaryButton } from "../styles/ButtonStyles";

const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      user {
        id
        firstName
        lastName
        email
      }
      token
    }
  }
`;

const FormWidth = styled.div`
  width: 480px;
  margin: 0 auto;

  .access-link-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }

  .create-account {
    text-align: right;
    float: right;
  }

  ${width.med`
    width: 80%;
  `}
`;

class SignIn extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };
  saveToState = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    return (
      <Mutation
        mutation={SIGNIN_MUTATION}
        variables={this.state}
        refetchQueries={[{ query: CURRENT_USER_QUERY }]}
      >
        {(signup, { error, loading }) => {
          return (
            <FormWidth>
              <FormStyles
                method="post"
                onSubmit={async (e) => {
                  e.preventDefault();
                  const res = await signup();
                  //we force a client side window refresh, so next js is able to do the
                  // validations of session at server side
                  document.cookie = `token=${res.data.signin.token}`;
                  localStorage.setItem("token", res.data.signin.token);
                  window.location.href = `/library`;
                }}
              >
                <fieldset disabled={loading} aria-busy={loading}>
                  <h1>Log In</h1>
                  <Error error={error} />
                  <div className="form-group">
                    <label htmlFor="email">
                      Email <RequiredIcon />
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.saveToState}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">
                      Password <RequiredIcon />
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.saveToState}
                      required
                    />
                  </div>
                  <PrimaryButton type="submit">Log In</PrimaryButton>
                </fieldset>
                <div className="access-link-container">
                  <Link href="/">
                    <a>Create an Account</a>
                  </Link>
                  <Link href="/password">
                    <a className="create-account">Forgot Password?</a>
                  </Link>
                </div>
              </FormStyles>
            </FormWidth>
          );
        }}
      </Mutation>
    );
  }
}

export default SignIn;
