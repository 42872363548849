import { OutlineButton } from "../styles/ButtonStyles";

const SignOut = (props) => (
  <OutlineButton
    className="sign-out-OutlineButton"
    onClick={async (e) => {
      e.preventDefault();
      localStorage.removeItem("token");
      window.location.href = "/signout";
    }}
  >
    Log Out
  </OutlineButton>
);

export default SignOut;
