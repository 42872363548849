import { Query } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";

const CURRENT_USER_QUERY = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      profileImage
      permissions
      myFavourites {
        id
        quantity
        item {
          id
          title
          excerpt
          content
          category
          verse
          premium
          audio
          meditationCategory
        }
      }
    }
  }
`;

const User = props => (
  <Query
    {...props}
    query={CURRENT_USER_QUERY}
    fetchPolicy={process.browser ? "cache-first" : "no-cache"}
  >
    {payload => {
      if (!payload.data.me)
        payload.data.me = {
          myFavourites: [],
          libraryItems: [],
          permissions: []
        };
      return props.children(payload);
    }}
  </Query>
);

User.propTypes = {
  children: PropTypes.func.isRequired
};

export default User;
export { CURRENT_USER_QUERY };
