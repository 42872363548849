import Link from "next/link";
import styled from "styled-components";
import User from "../utils/User";
import { Query } from "react-apollo";
import { theme, width } from "../utils";
import { withRouter } from "next/router";
import NavAvatar from "./NavAvatar";
import { IS_SUBSCRIBED } from "../_graphql/queries/is_subscribed";

const NavStyles = styled.ul`
  width: 100%;

  .nav-item-container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0px;

    position: absolute;
    top: 0px;
    right: 30px;
    color: ${theme.darkColor};
    font-family: ${theme.navItemFont};
    font-size: 1.6rem;
    margin-top: 32px;
    text-align: right;

    a {
      margin: 0 15px;
    }
    ${width.small`
      top: -32px;
      right: -10px;
      a {
          margin: 0 10px;
        }
    `}
  }
  ${width.small`
    .mobile-hidden {
      display: none;
    }
    font-size: 1.4rem;
 `}
`;

const NavItem = styled.a`
  margin: 0 15px;
  color: ${(props) =>
    props.active ? "#EAA976 !important" : "#EAA976 !important"};
  font-family: ${(props) =>
    props.active
      ? theme.bodyFontBold + "!important"
      : theme.bodyFontBold + "!important"};
  cursor: pointer;
  font-size: 1.8rem;
  ${width.small`
    .mobile-hidden {
      display: none;
    }
    font-size: 1.4rem;
 `}
`;

class NavItems extends React.Component {
  render() {
    const { router } = this.props;
    return (
      <User>
        {({ data: { me } }) => (
          <NavStyles>
            {me && (
              <div className="nav-item-container">
                <Query query={IS_SUBSCRIBED}>
                  {({ data: { getStripeCustomer } }) => {
                    console.log(getStripeCustomer);
                    return (
                      <div>
                        {getStripeCustomer.subscriptions.data.length < 1 ? (
                          <div>
                            <Link href="/library" className="mobile-hidden">
                              <NavItem active={router.pathname === "/library"}>
                                Library
                              </NavItem>
                            </Link>
                            <Link href="/upgrade">
                              <NavItem active={router.pathname === "/upgrade"}>
                                Upgrade
                              </NavItem>
                            </Link>
                            <Link href="/settings" className="mobile-hidden">
                              <NavItem active={router.pathname === "/settings"}>
                                My Account
                              </NavItem>
                            </Link>
                          </div>
                        ) : (
                          <div>
                            <Link href="/library" className="mobile-hidden">
                              <NavItem active={router.pathname === "/library"}>
                                Library
                              </NavItem>
                            </Link>
                            <Link href="/my-buddhacloud">
                              <NavItem
                                active={router.pathname === "/my-buddhacloud"}
                              >
                                My Buddhacloud
                              </NavItem>
                            </Link>
                            <Link href="/settings" className="mobile-hidden">
                              <NavItem active={router.pathname === "/settings"}>
                                My Account
                              </NavItem>
                            </Link>
                          </div>
                        )}
                      </div>
                    );
                  }}
                </Query>

                {/* <div className="nav-avatar-container">
                  <NavAvatar />
                </div> */}
              </div>
            )}
          </NavStyles>
        )}
      </User>
    );
  }
}

export default withRouter(NavItems);
