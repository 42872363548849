import User from "../utils/User";
import Link from "next/link";
import styled from "styled-components";
import NavMenu from "./NavMenu";
import { theme, width } from "../utils";

const Avatar = styled.div`
  position: relative;
  top: -30px;


  button {
    display: none;
    height: 30px;
    width: 30px;
    padding: 4px;
    position: absolute;
    top: 49px;
    right: -80px;
    z-index: 1;
  }

  ${width.large`
    right: 90px;
    button {
      background-image: url('/static/icons/hamburger.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-color: transparent;
      border: none;
      display: block;
      height: 30px;
      width: 30px;
      padding: 4px;
      position: absolute;
      top: 49px;
      right: -80px;
      z-index: 1;
      cursor: pointer;
    }
  `}

  ${width.med`
    button {
      top: 49px;
      right: -90px;
    }
  `}

  ${width.small`
    button {
      top: 38px;
      right: -90px;
    }
  `}
  
  .avatar-link {
    background-image: url('/static/user-avatar.svg');
    background-repeat: no-repeat;
    background-position: 0px 20px;
    height: 78px;
    padding-top: 20px;
    display: grid;
    grid-template-columns: 38px auto;
    grid-gap: 5px;

    ${width.large`
      display: none;
    `}
  }

  img {
    height: 38px;
    width: 38px;
    border-radius: 100%;

    ${width.small`
      height: 24px!important;
      width: 24px!important;
      border-radius: 100%;
    `}
  }

  .username {
    color: ${theme.darkColor};
    font-family: ${theme.navItemFont};
    font-size: 1.8rem;
    padding-top: 8px;
    margin-left: 10px;
    

    ${width.small`
      padding-top: 0px;
    `}
  }
`;

export default class NavAvatar extends React.Component {
  render() {
    return (
      <User>
        {({ data }) => {
          const me = data ? data.me : null;
          return (
            <Avatar>
              <Link href="/settings">
                <a className="avatar-link">
                  <img
                    src={
                      !data.me.profileImage
                        ? "/static/user-avatar.svg"
                        : data.me.profileImage
                    }
                    className="user-avatar"
                  />
                  <User>
                    {({ data: { me } }) => {
                      return (
                        <div className="username">{data.me.firstName}</div>
                      );
                    }}
                  </User>
                </a>
              </Link>
              <NavMenu />
            </Avatar>
          );
        }}
      </User>
    );
  }
}
