import { Query } from 'react-apollo';
import { CURRENT_USER_QUERY } from './User';
import NavBrand from '../nav/NavBrand';
import SignIn from '../access/SignIn';
import AccessStyles from '../styles/AccessStyles';

const PleaseSignIn = props => (
  <Query query={CURRENT_USER_QUERY}>
    {({ data, loading }) => {
      if (loading) return <p>Loading...</p>;
      if (!data.me) {
        return (
          <div>
            <NavBrand />
            <AccessStyles>
              <SignIn />
            </AccessStyles>
          </div>
        );
      }
      return props.children;
    }}
  </Query>
);

export default PleaseSignIn;
