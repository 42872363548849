import styled from "styled-components";
import Link from "next/link";
import ModalStyles from "../styles/ModalStyles";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import DeviceMenuStyles from "../styles/DeviceMenuStyles";
import { withRouter } from "next/router";
import { theme } from "../utils";
import SignOut from "../access/SignOut";

const NavItem = styled.a`
  color: ${props =>
    props.active ? "#18645D !important" : "#829C99 !important"};
  font-family: ${props =>
    props.active
      ? theme.bodyFontBold + "!important"
      : theme.bodyFontBold + "!important"};
  cursor: pointer;
`;

const LOCAL_DEVICE_MENU_STATE_QUERY = gql`
  query {
    deviceMenuOpen @client
  }
`;

const TOGGLE_DEVICE_MENU_MUTATION = gql`
  mutation {
    toggleDeviceMenu @client
  }
`;

class NavMenu extends React.Component {
  render() {
    const { router } = this.props;
    return (
      <Mutation mutation={TOGGLE_DEVICE_MENU_MUTATION}>
        {toggleDeviceMenu => (
          <Query query={LOCAL_DEVICE_MENU_STATE_QUERY}>
            {({ data }) => (
              <DeviceMenuStyles open={data.deviceMenuOpen}>
                <div className="modal-container">
                  <button onClick={toggleDeviceMenu} className="close">
                    <img src="/static/close.svg" alt="Close Modal" />
                  </button>

                  <div onClick={toggleDeviceMenu}>
                    <Link href="/shop">
                      <NavItem active={router.pathname === "/shop"}>
                        Dashboard
                      </NavItem>
                    </Link>
                  </div>
                  <div onClick={toggleDeviceMenu}>
                    <Link href="/sell">
                      <NavItem active={router.pathname === "/sell"}>
                        Vendors
                      </NavItem>
                    </Link>
                  </div>
                  <div onClick={toggleDeviceMenu}>
                    <Link href="/#">
                      <NavItem active={router.pathname === "/#"}>
                        Favourites
                      </NavItem>
                    </Link>
                  </div>

                  <div className="menu-divider" />

                  <div onClick={toggleDeviceMenu}>
                    <Link href="/settings/my-account">
                      <NavItem
                        active={router.pathname === "/settings/my-purchases"}
                      >
                        My Purchases
                      </NavItem>
                    </Link>
                  </div>
                  <div onClick={toggleDeviceMenu}>
                    <Link href="/settings/my-partner">
                      <NavItem
                        active={router.pathname === "/settings/listings"}
                      >
                        My Listings
                      </NavItem>
                    </Link>
                  </div>
                  <div onClick={toggleDeviceMenu}>
                    <Link href="/settings/my-wedding">
                      <NavItem active={router.pathname === "/settings/details"}>
                        My details
                      </NavItem>
                    </Link>
                  </div>
                  <div onClick={toggleDeviceMenu}>
                    <Link href="/settings/my-wedding">
                      <NavItem active={router.pathname === "/settings/billing"}>
                        My Billing
                      </NavItem>
                    </Link>
                  </div>
                  <div onClick={toggleDeviceMenu}>
                    <Link href="/settings/help">
                      <NavItem active={router.pathname === "/settings/help"}>
                        Help
                      </NavItem>
                    </Link>
                  </div>

                  <div className="menu-divider" />

                  <div className="device-sign-out">
                    <SignOut />
                  </div>
                </div>
              </DeviceMenuStyles>
            )}
          </Query>
        )}
      </Mutation>
    );
  }
}

export default withRouter(NavMenu);
export { LOCAL_DEVICE_MENU_STATE_QUERY, TOGGLE_DEVICE_MENU_MUTATION };
