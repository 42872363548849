export const theme = {
  primaryColor: "#EAA976",
  bgColor: "#FAF6F1",
  navbarHeight: "120px",
  darkText: "#4C5053",
  orange: "#EAA976",
  brown: "#846162",

  // Form Title
  formTitleColor: "#000000",
  formTitleFontSize: "2.6rem",
  formTitleFontFamily: "granville-reg",
  formTitleLineHeight: "3.2px",
  formTitleFontLetterSpacing: "1.2px",
  formTitleMargin: "20px 0 20px 0",
  formTitleTextTransform: "uppercase",

  // Form Label
  labelColor: "#000000",
  labelFontSize: "1.4rem",
  labelFontFamily: "granville",
  labelFontLetterSpacing: "1.2px",
  labelLineHeight: "1.8rem",
  labelTextTransform: "uppercase",

  // Form Input
  inputBGColor: "white",
  inputBorderColor: "#EAA976",
  inputBorderRadius: "0px",
  inputFontColor: "#5F666B",
  inputFontSize: "1.6rem",
  inputFontFamily: "Avenir-Book",
  inputLineHeight: "1.8rem",
  inputHeight: "52px",
  inputPaddingTop: "",
  inputPaddingBottom: "",
  inputPaddingLeft: "0px",
  inputPaddingRight: "",

  // Primary Button
  primaryButtonBGColor: "#EAA976",
  primaryButtonBorderColor: "#EAA976",
  primaryButtonBorderRadius: "0px",
  primaryButtonColor: "#ffffff",
  primaryButtonFontFamily: "granville",
  primaryButtonFontSize: "1.6rem",
  primaryButtonLineHeight: "100%",
  primaryButtonLetterSpacing: "0px",
  primaryButtonHeight: "52px",
  primaryButtonWidth: "100%",
  primaryButtonMargin: "10px 0",
  primaryButtonPaddingTop: "",
  primaryButtonPaddingBottom: "",
  primaryButtonPaddingLeft: "",
  primaryButtonPaddingRight: "",
  primaryButtonTextTransform: "uppercase",

  // Primary Button
  secondaryButtonBGColor: "transparent",
  secondaryButtonBorderColor: "#EAA976",
  secondaryButtonBorderRadius: "2px",
  secondaryButtonColor: "#EAA976",
  secondaryButtonFontFamily: "",
  secondaryButtonFontSize: "1.6rem",
  secondaryButtonLineHeight: "100%",
  secondaryButtonLetterSpacing: "0px",
  secondaryButtonHeight: "52px",
  secondaryButtonWidth: "100%",
  secondaryButtonMargin: "10px 0",
  secondaryButtonPaddingTop: "",
  secondaryButtonPaddingBottom: "",
  secondaryButtonPaddingLeft: "",
  secondaryButtonPaddingRight: "",
  secondaryButtonTextTransform: "uppercase",

  // Nav Styles

  navBarBGColor: "#ffffff",
  navItemFont: "Avenir-Book",
  navItemLetterSpacing: "1px",
  //

  biege: "#f8f4ef",
  green: "#EAA976",
  lightGreen: "#829C99",
  white: "#ffffff",
  offWhite: "#f1f1f1",

  titleFont: "SuperiorTitle-Bold",
  bodyFont: "ProximaNova-Reg",
  bodyFontSemiBold: "ProximaNova-Bold",
  bodyFontBold: "Avenir-Book",

  titleFontLetterSpacing: "1px",
  bodyFontLetterSpacing: "0.5px",
  bodyFontBoldLetterSpacing: "0.5px",
  formLabelLetterSpacing: "0.5px",
  formInputLetterSpacing: "0.5px",
  primaryButtonLetterSpacing: "2px",
};
